import "./Footer.scss";
function Footer({ children }) {
  return (
    <div className="footer">
      <span className="footer__copy">
        © Design by{" "}
        <a target="_blank" href="https://t.me/chungpham0412" rel="noreferrer">
          Mr Chung
        </a>
        .
      </span>
    </div>
  );
}

export default Footer;
