import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Portfolio from "../Portfolio/Tabs/Tabs";
import "../../assets/stylesheet/global.scss";

function Home() {
  return (
    <>
      <Header />
      <Portfolio
        children={[
          { title: "Projects", view: "Projects" },
          { title: "Skills", view: "Skills" },
        ]}
      />
      <Footer />
    </>
  );
}

export default Home;
