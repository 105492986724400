import React, { useState } from "react";
import "./Tabs.scss";
import Projects from "../Projects/Projects";
import Skills from "../Skills/Skills";

function Tabs(props) {
  const [activeTab, setActiveTab] = useState(0);
  const { children } = props;
  const onClickTab = (index) => {
    setActiveTab(index);
  };
  return (
    <div className="portfolio-tabs">
      <div className="container">
        <ul className="portfolio-tabs__list">
          {children.map((child, index) => {
            return (
              <li
                className={`portfolio-tabs__item ${
                  activeTab === index ? "active" : ""
                }`}
                key={index}
                onClick={() => onClickTab(index)}
              >
                {child.title}
              </li>
            );
          })}
        </ul>
        <div className="tabs__content">
          {children.map((child, index) => {
            return (
              <div
                className={`portfolio-tabs__item ${
                  activeTab === index ? "active" : ""
                }`}
                key={index}
              >
                {child.view === "Projects" ? <Projects /> : <Skills />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Tabs;
