import React from "react";
import "./Projects.scss";
import timind from "../../../assets/image/project/timind.png";
import profbeauty from "../../../assets/image/project/profbeauty.png";
import thienvu from "../../../assets/image/project/thienvu.png";
import uskolor from "../../../assets/image/project/uskolor.png";
import ProjectItem from "./ProjectItem";

function Projects() {
  const Datas = [
    {
      subTitle: "Timind.co",
      title: "Timind.co",
      description: `
        <ul>
          <li>Convert Figma to HTML.</li>
          <li>Add animation effect when scrolling.</li>
          <li>Create a mouse effect that follows the screen using Gsap js.</li>
          <li>Process candidate registration forms and upload cv files.</li>
        </ul>
      `,
      image: timind,
      href: "https://timind.co",
      tags: [
        {
          name: "figma",
          href: "",
        },
        {
          name: "php",
          href: "",
        },
        {
          name: "gsap.js",
          href: "",
        },
        {
          name: "aos",
          href: "",
        },
      ],
    },
    {
      title: "Profbeauty.vn",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quis lorem ut liber",
      image: profbeauty,
      href: "https://profbeauty.vn",
      tags: [
        {
          name: "figma",
          href: "",
        },
        {
          name: "php",
          href: "",
        },
        {
          name: "infinite-scroll.js",
          href: "",
        },
      ],
    },
    {
      title: "Thiên vũ Audio",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quis lorem ut liber",
      href: "https://v2024.thienvu.com.vn",
      image: thienvu,
      tags: [
        {
          name: "php",
          href: "",
        },
        {
          name: "Elasticsearch",
          href: "",
        },
      ],
    },
    {
      title: "Uskolor",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quis lorem ut liber",
      href: "https://uskolor.com",
      image: uskolor,
      tags: [
        {
          name: "php",
          href: "",
        },
        {
          name: "Phối màu tường",
          href: "",
        },
      ],
    },
  ];
  const ListItems = (props) => {
    return (
      <>
        {props.projects.map((item, index) => (
          <ProjectItem key={index} project={item} />
        ))}
      </>
    );
  };
  return (
    <div className="skills">
      <div className="skills__content">
        <ListItems projects={Datas} />
      </div>
    </div>
  );
}

export default Projects;
