import Modal from "../Modal";
import "./ProjectModal.scss";
function ProjectModel({
  modalOpen,
  setModalOpen,
  project,
  children,
  ...props
}) {
  return (
    <>
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <div className="project__modal">
          <div className="project__modal__body">
            <div className="content">
              <div className="content__left">
                <img src={project.image} alt="" className="project__img" />
              </div>
              <div className="content__right">
                <h2 className="project__title">{project.title}</h2>
                {project.tags && (
                  <div className="project__tags">
                    {project.tags.map((tag, i) => (
                      <span className="tag_item" key={i}>
                        {tag?.name}
                      </span>
                    ))}
                  </div>
                )}
                {/* parser description from string to html */}
                {project.description && (
                  <div
                    className="project__desc"
                    dangerouslySetInnerHTML={{ __html: project.description }}
                  />
                )}
                <div className="project__buttons">
                  <a
                    className="button button__small project__button project__button--link"
                    href={project.href}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Visit Project
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProjectModel;
