import { Fragment, React } from "react";
import "./Modal.scss";
const Modal = ({ modalOpen, setModalOpen, children, ...props }) => {
  const closeHandler = (e) => {
    setModalOpen(false);
  };
  return (
    <Fragment>
      <div className="module__popup">
        <span className="module__popup__close" onClick={closeHandler}>
          &times;
        </span>
        <div className="module__popup__content">{children}</div>
      </div>
      <div
        className={"overlay " + (modalOpen ? "active" : "")}
        onClick={closeHandler}
      ></div>
    </Fragment>
  );
};

export default Modal;
